export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [];

export const dictionary = {
		"/(public)": [18,[3]],
		"/(private)/catalogues": [4,[2]],
		"/(private)/catalogues/[slug]": [5,[2]],
		"/(private)/cost-element-catalogues/[slug]": [6,[2]],
		"/(private)/documents": [7,[2]],
		"/(private)/documents/[slug]": [8,[2]],
		"/(private)/firm-management": [9,[2]],
		"/(public)/forgot-login-names": [19,[3]],
		"/(public)/forgot-password": [20,[3]],
		"/(public)/login": [21,[3]],
		"/(public)/no-desktop": [22,[3]],
		"/(public)/noauth": [23,[3]],
		"/(private)/order-management": [10,[2]],
		"/(private)/partner-addresses": [11,[2]],
		"/(private)/partner-addresses/[slug]": [12,[2]],
		"/(private)/queue-tasks": [13,[2]],
		"/(public)/reset-password": [24,[3]],
		"/(private)/settings": [14,[2]],
		"/(private)/settings/[slug]": [15,[2]],
		"/(public)/signup": [25,[3]],
		"/(private)/system-info": [16,[2]],
		"/(private)/text-blocks": [17,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';